import { useSelector } from "react-redux";
import SuperAdminPassword from "../../../../components/SuperAdminPassword"
import DateRangePicker from "../../ui/dateRangePicker";
import { RootState } from "../../../../modules";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getReservationCountByWellnessLectureStartDateTimeBetweenGroupByTimeAndTeacher } from "../../../../service/report";
import TeacherReportTimeChart from "./ui/chart";
import TeacherReportTimeTable from "./ui/table";

const ReportTeacherTime = () => {
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);
    const [data, setData] = useState<IReportTeacherReservationTimeAdminResponseV1[]>([]);
    const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
    const startZDT = dayjs().set('h', 0).set('minute', 0).set('s', 0).subtract(7, 'week')
    const requestReportMember = (startZDT: string, endZDT: string) => {
        if (!isSuperAdmin) return;
        setIsGetLoading(true);
        getReservationCountByWellnessLectureStartDateTimeBetweenGroupByTimeAndTeacher(startZDT, endZDT)
            .then(res => { setData(res.data) })
            .catch(err => { console.error(err); })
            .finally(() => setIsGetLoading(false));
    }

    useEffect(() => {
        requestReportMember(startZDT.toISOString(), dayjs().set('h', 23).set('minute', 59).set('s', 59).toISOString())
    }, [isSuperAdmin])

    // teacherId: number
    // teacherName: string
    // targetTime: string
    // reservationCount: number
    const groupDataByName = (data: IReportTeacherReservationTimeAdminResponseV1[]) => {
        if (data.length === 0) return [];
        const grouped: { [key: string]: any } = {};
        data.forEach(item => {
            if (!grouped[item.teacherId]) {
                grouped[item.teacherId] = {
                    teacherId: item.teacherId,
                    teacherName: item.teacherName,
                    [item.targetTime]: 0,
                };
            }
            grouped['Total'] = {
                ...grouped['Total'],
                teacherId: 'Total',
                teacherName: 'Total',
                reservationCount: data.reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                [item.targetTime]: 0,
            }
        })

        data.forEach(item => {
            grouped[item.teacherId][item.targetTime] = item.reservationCount || 0;
            grouped['Total'][item.targetTime] += item.reservationCount || 0;
        }
        );

        return Object.values(grouped);
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        <DateRangePicker
            startDate={startZDT}
            onClick={(startDate: string, endDate: string) => requestReportMember(dayjs(startDate + 'T00:00:00').toISOString(), dayjs(endDate + 'T23:59:59').toISOString())} />
        <br /><br />
        <TeacherReportTimeChart value={data} />
        <br /><br />
        <TeacherReportTimeTable data={groupDataByName(data).map((q, idx) => ({ ...q, key: idx }))} isLoading={isGetLoading} />
    </>
}

export default ReportTeacherTime;
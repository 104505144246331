import { useSelector } from "react-redux";
import SuperAdminPassword from "../../../../components/SuperAdminPassword"
import DateRangePicker from "../../ui/dateRangePicker";
import { RootState } from "../../../../modules";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getReservationCountByWellnessLectureStartDateTimeBetweenGroupByTeacherAndReservationStatus } from "../../../../service/report";
import TeacherReportReservaionChart from "./ui/chart";
import TeacherReportReservationTable from "./ui/table";
import { getReservationAndCheckInStatusToText } from "../../../../utils/statusText";

const ReportTeacherReservaion = () => {
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);
    const [data, setData] = useState<ReportTeacherReservationAdminResponseV1[]>([]);
    const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
    const startZDT = dayjs().set('h', 0).set('minute', 0).set('s', 0).subtract(7, 'week')
    const requestReportMember = (startZDT: string, endZDT: string) => {
        if (!isSuperAdmin) return;
        setIsGetLoading(true);
        getReservationCountByWellnessLectureStartDateTimeBetweenGroupByTeacherAndReservationStatus(startZDT, endZDT)
            .then(res => { setData(res.data.sort((a, b) => b.reservationCount - a.reservationCount)); })
            .catch(err => { console.error(err); })
            .finally(() => setIsGetLoading(false));
    }

    useEffect(() => {
        requestReportMember(startZDT.toISOString(), dayjs().set('h', 23).set('minute', 59).set('s', 59).toISOString())
    }, [isSuperAdmin])

    const groupDataByName = (data: ReportTeacherReservationAdminResponseV1[]) => {
        if (data.length === 0) return [];
        const grouped: { [key: string]: any } = {};
        data.forEach(item => {
            if (!grouped[item.teacherId]) {
                grouped[item.teacherId] = {
                    teacherId: item.teacherId,
                    teacherName: item.teacherName,
                    reservationCount: 0,
                    CLASS_BEFORE: 0,
                    RESERVATION: 0,
                    CHECK_IN_RESERVATION_CANCELED: 0,
                    CHECK_IN: 0,
                    ABSENT: 0,
                    ABSENTRatio: 0,
                    RESERVATIONRatio: 0,
                };
            }
        })
        if (!grouped['Total']) {
            grouped['Total'] = {
                teacherId: 'Total',
                teacherName: 'Total',
                reservationCount: data.reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                CLASS_BEFORE: null,
                RESERVATION: null,
                CHECK_IN_RESERVATION_CANCELED: null,
                CHECK_IN: null,
                ABSENT: null,
                ABSENTRatio: null,
                RESERVATIONRatio: null,
            }
        }
        data.forEach(item => {
            switch (item.checkInStatus) {
                case 'CLASS_BEFORE':
                    grouped[item.teacherId].CLASS_BEFORE += item.reservationCount || 0;
                    grouped[item.teacherId].RESERVATION += item.reservationCount || 0;
                    grouped['Total'].CLASS_BEFORE += item.reservationCount || 0;
                    grouped['Total'].RESERVATION += item.reservationCount || 0;
                    break;
                case 'CHECK_IN':
                    grouped[item.teacherId].CHECK_IN = item.reservationCount || 0;
                    grouped[item.teacherId].RESERVATION += item.reservationCount || 0;
                    grouped['Total'].CHECK_IN += item.reservationCount || 0;
                    grouped['Total'].RESERVATION += item.reservationCount || 0;
                    break;
                case 'ABSENT':
                    grouped[item.teacherId].ABSENT = item.reservationCount || 0;
                    grouped[item.teacherId].RESERVATION += item.reservationCount || 0;
                    grouped['Total'].ABSENT += item.reservationCount || 0;
                    grouped['Total'].RESERVATION += item.reservationCount || 0;
                    break;
                case 'CHECK_IN_RESERVATION_CANCELED':
                    grouped[item.teacherId].CHECK_IN_RESERVATION_CANCELED = item.reservationCount || 0;
                    grouped[item.teacherId].RESERVATION += item.reservationCount || 0;
                    grouped['Total'].CHECK_IN_RESERVATION_CANCELED += item.reservationCount || 0;
                    grouped['Total'].RESERVATION += item.reservationCount || 0;
                    break;
                default:
                    break;
            }
        });

        Object.values(grouped).forEach(item => {
            grouped[item.teacherId].ABSENTRatio = (item.ABSENT / item.RESERVATION * 100).toFixed(2);
            grouped[item.teacherId].RESERVATIONRatio = (item.CHECK_IN_RESERVATION_CANCELED / item.RESERVATION * 100).toFixed(2);
        })

        return Object.values(grouped).sort((a, b) => a.teacherName === 'Total' ? 1 : b.RESERVATION - a.RESERVATION);
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        <DateRangePicker
            startDate={startZDT}
            onClick={(startDate: string, endDate: string) => requestReportMember(dayjs(startDate + 'T00:00:00').toISOString(), dayjs(endDate + 'T23:59:59').toISOString())} />
        <br /><br />
        <TeacherReportReservaionChart value={data.map(q => ({ teacherName: q.teacherName, count: q.reservationCount, status: getReservationAndCheckInStatusToText(q.checkInStatus) }))} />
        <br /><br />
        <TeacherReportReservationTable data={groupDataByName(data.map((q, idx) => ({ ...q, key: idx })))} isLoading={isGetLoading} />
    </>
}

export default ReportTeacherReservaion;
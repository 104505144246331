import { useSelector } from "react-redux";
import SuperAdminPassword from "../../../../components/SuperAdminPassword"
import DateRangePicker from "../../ui/dateRangePicker";
import { RootState } from "../../../../modules";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getReservationCountAndDailyByWellnessLectureStartDateTimeBetweenGroupByTeacherAndDaily } from "../../../../service/report";
import TeacherReportReservationDailyChart from "./ui/chart";
import TeacherReportReservationDailyTable from "./ui/table";
import { numberToday } from "../../../../utils/Converte";

const ReportTeacherReservationDaily = () => {
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);
    const [data, setData] = useState<ReportTeacherReservationDailyAdminResponseV1[]>([]);
    const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
    const startZDT = dayjs().set('h', 0).set('minute', 0).set('s', 0).subtract(7, 'week')
    const requestReportMember = (startZDT: string, endZDT: string) => {
        if (!isSuperAdmin) return;
        setIsGetLoading(true);
        getReservationCountAndDailyByWellnessLectureStartDateTimeBetweenGroupByTeacherAndDaily(startZDT, endZDT)
            .then(res => { setData(res.data.sort((a, b) => a.day - b.day)); })
            .catch(err => { console.error(err); })
            .finally(() => setIsGetLoading(false));
    }

    useEffect(() => {
        requestReportMember(startZDT.toISOString(), dayjs().toISOString())
    }, [isSuperAdmin])

    const groupDataByName = (data: ReportTeacherReservationDailyAdminResponseV1[]) => {
        if (data.length === 0) return [];
        const grouped: { [key: string]: any } = {};
        data.forEach(item => {
            if (!grouped[item.teacherId]) {
                grouped[item.teacherId] = {
                    key: item.teacherId,
                    teacherId: item.teacherId,
                    teacherName: item.teacherName,
                    reservationCount: 0,
                    monday: 0,
                    tuesday: 0,
                    wednesday: 0,
                    thursday: 0,
                    friday: 0,
                    saturday: 0,
                    sunday: 0,
                    total: 0,
                };
            }
        })
        if (!grouped['Total']) {
            grouped['Total'] = {
                key: 0,
                teacherId: 'Total',
                teacherName: 'Total',
                reservationCount: data.reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                monday: data.filter(q => q.day === 1).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                tuesday: data.filter(q => q.day === 2).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                wednesday: data.filter(q => q.day === 3).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                thursday: data.filter(q => q.day === 4).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                friday: data.filter(q => q.day === 5).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                saturday: data.filter(q => q.day === 6).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                sunday: data.filter(q => q.day === 7).reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
                total: data.reduce((acc, cur) => acc + (cur.reservationCount ?? 0), 0),
            }
        }
        data.forEach(item => {
            grouped[item.teacherId].total += item.reservationCount || 0;
            switch (item.day) {
                case 1:
                    grouped[item.teacherId].monday = item.reservationCount || 0;
                    break;
                case 2:
                    grouped[item.teacherId].tuesday = item.reservationCount || 0;
                    break;
                case 3:
                    grouped[item.teacherId].wednesday = item.reservationCount || 0;
                    break;
                case 4:
                    grouped[item.teacherId].thursday = item.reservationCount || 0;
                    break;
                case 5:
                    grouped[item.teacherId].friday = item.reservationCount || 0;
                    break;
                case 6:
                    grouped[item.teacherId].saturday = item.reservationCount || 0;
                    break;
                case 7:
                    grouped[item.teacherId].sunday = item.reservationCount || 0;
                    break;
                default:
                    break;
            }
        });
        return Object.values(grouped).sort((a, b) => a.teacherName === 'Total' ? 1 : b.total - a.total);
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }
    return <>
        <DateRangePicker
            startDate={startZDT}
            onClick={(startDate: string, endDate: string) => requestReportMember(dayjs(startDate + 'T00:00:00').toISOString(), dayjs(endDate).toISOString())} />
        <br /><br />
        <TeacherReportReservationDailyChart value={data.map(q => ({ teacherName: q.teacherName, reservationCount: q.reservationCount, day: numberToday(q.day) }))} />
        <br /><br />
        <TeacherReportReservationDailyTable data={groupDataByName(data.map((q, idx) => ({ ...q, key: idx })))} isLoading={isGetLoading} />
    </>
}

export default ReportTeacherReservationDaily;
export const getReservationAndCheckInStatusToText = (status: TReservationStatus | TCheckInStatus) => {
    switch (status) {
        case 'RESERVATION':
            return '예약'
        case 'CANCELED_RESERVATION':
            return '예약 취소'
        case 'CLASS_BEFORE':
            return '수업전'
        case 'CHECK_IN_RESERVATION_CANCELED':
            return '출석 취소'
        case 'CHECK_IN':
            return '출석'
        case 'ABSENT':
            return '결석'
    }
}

export const getReservationTextToStatus = (status: string): TReservationStatus | TCheckInStatus | string => {
    switch (status) {
        case '예약':
            return 'RESERVATION'
        case '예약 취소':
            return 'CANCELED_RESERVATION'
        case '수업전':
            return 'CLASS_BEFORE'
        case '출석 취소':
            return 'CHECK_IN_RESERVATION_CANCELED'
        case '출석':
            return 'CHECK_IN'
        case '결석':
            return 'ABSENT'
        default:
            return status
    }
}

export const paymentMethodEnumListToKoreanString = (paymentMethods: string[]) => {
    const isPaymentMethod = (value: string): value is 'CARD' | 'CASH' | 'TRANSFER' | 'UNPAID' => value === 'CARD' || value === 'CASH' || value === 'TRANSFER' || value === 'UNPAID';
    const typeToKoreanMap = {
        'CARD': '카드',
        'CASH': '현금',
        'TRANSFER': '가상계좌',
        'UNPAID': '미결제',
    };
    return paymentMethods.map((type) => {
        let trimType = type.trim();
        if (isPaymentMethod(trimType)) {
            return typeToKoreanMap[trimType] ?? trimType;
        } else {
            return trimType;
        }
    }).toLocaleString().replaceAll(",", ", ")
}
import { CSSProperties, useEffect } from 'react';
import { Upload, message } from 'antd';
import type { UploadFile } from 'antd';
import ImgCrop from 'antd-img-crop';
import './index.css'
import { UploadListType } from 'antd/es/upload/interface';

interface IProps {
    setFileList: Function;
    fileList: UploadFile[];
    imgCropAspect?: [number, number];
    maxImg?: number;
    listType?: UploadListType;
    imgListGap?: string;
    uploadIconText?: string;
    deleteOnRemove?: boolean
    onChange?: Function;
}
interface CustomCSSProperties extends CSSProperties {
    '--img-crop-aspect-0'?: string;
    '--img-crop-aspect-1'?: string;
    '--img-list-gap'?: string;
    '--display-anticon-delete-icon'?: string;
}

const UploadCropCustom = ({ setFileList, fileList = [], imgCropAspect, maxImg = 1, listType = 'picture-card', imgListGap = '24px', uploadIconText, deleteOnRemove = false, onChange }: IProps) => {
    const handleFileUpload = (file: any) => {
        const uploadFile: UploadFile = {
            uid: file.uid,
            name: file.name,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            size: file.size,
            type: file.type,
            originFileObj: file
        };
        setFileList((prevFileList: any) => [...prevFileList, uploadFile]);
    };
    const fileToBase64 = (file: File): Promise<string> => {
        handleFileUpload(file)
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };
    const customRequest = async (file: any) => {
        if (!file) return;
        const uploadCheck = beforeUpload(file)
        if (!uploadCheck) return;
        file.preview = await fileToBase64(file);
    };
    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (file.thumbUrl) {
            src = file.thumbUrl
        }
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as any);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        if (image.style) {
            image.style.display = 'block';
            image.style.margin = 'auto';
        }
        const imgWindow = window.open("", '_blank');
        if (imgWindow) {
            imgWindow.document.body.style.display = 'flex'
            imgWindow.document.body.style.justifyItems = 'center'
            imgWindow.document.body.appendChild(image);
        }
    };

    const cropStyle = (): CustomCSSProperties => {
        const imgCropAspect0 = imgCropAspect?.[0] ? imgCropAspect?.[0] / 6 + "px" : "72px"
        const imgCropAspect1 = imgCropAspect?.[1] ? imgCropAspect?.[1] / 6 + "px" : "72px"
        return {
            '--img-crop-aspect-0': imgCropAspect0,
            '--img-crop-aspect-1': imgCropAspect1,
            '--img-list-gap': imgListGap,
            '--display-anticon-delete-icon': deleteOnRemove ? 'none' : 'inline-flex'
        }
    }
    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('.jpg, .jpeg, .png 파일만 지원 합니다.');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('이미지 크기는 2MB보다 작아야합니다.');
        }
        return isJpgOrPng && isLt2M;
    };

    useEffect(() => {
        onChange?.(fileList)
    }, [fileList])

    return (
        <div style={cropStyle()}>
            {imgCropAspect ? <ImgCrop aspect={imgCropAspect[0] / imgCropAspect[1]} rotationSlider>
                <Upload
                    customRequest={(e) => { customRequest(e.file); }}
                    listType={listType}
                    fileList={fileList}
                    maxCount={maxImg}
                    onPreview={e => onPreview(e)}
                    onRemove={e => deleteOnRemove ? undefined : setFileList((curr: any) => curr?.filter((c: any) => c.uid !== e.uid))}
                >
                    {fileList.length < maxImg && (uploadIconText ? uploadIconText : " + Upload")}
                </Upload>
            </ImgCrop>
                : <Upload
                    customRequest={(e) => { customRequest(e.file); }}
                    listType={listType}
                    fileList={fileList}
                    maxCount={maxImg}
                    onPreview={e => onPreview(e)}
                    onRemove={e => deleteOnRemove ? undefined : setFileList((curr: any) => curr?.filter((c: any) => c.uid !== e.uid))}
                >
                    {fileList.length < maxImg && (uploadIconText ? uploadIconText : " + Upload")}
                </Upload>}
        </div>
    );
};

export default UploadCropCustom;
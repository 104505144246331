import { AxiosResponse } from 'axios';
import api from './Api'

export const createIssuedWellnessTicketAndOnSitePayByUserDtoAndIssuedWellnessTicketCreateAdminRequest = (formData: IIssuedWellnessTicketCreateAdminRequestV2): Promise<AxiosResponse<boolean>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/admin/v2`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}
export const getUpdateDataForIssuedWellnessTicketAndOnSitePayByIssuedWellnessTicketId = (issuedWellnessTicketId: number): Promise<AxiosResponse<IIssuedTicketIdAddOnSitePay>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/update/on-site/admin/v2/${issuedWellnessTicketId}`)
}
export const getUpdateDataForIssuedWellnessTicketAndInAppPayByIssuedWellnessTicketId = (issuedWellnessTicketId: number): Promise<AxiosResponse<IIssuedTicketIdAddPay>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/update/in-app/admin/v2/${issuedWellnessTicketId}`)
}
export const updateIssuedWellnessTicketByIssuedWellnessTicketBasicAdminRequestInApp = (issuedWellnessTicketBasicAdminRequest: any): Promise<AxiosResponse<boolean>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/in-app/admin/v2`, issuedWellnessTicketBasicAdminRequest);
}
export const updateIssuedWellnessTicketIsDeleteByIssuedWellnessTicketIdAndBoolean = (isseudWellnessTicketIsDeleteAdminRequest: any) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/is-delete/admin/v2`, isseudWellnessTicketIsDeleteAdminRequest)
}
export const updateIssuedWellnessTicketAndOnSitePayByUserDtoAndIssuedWellnessTicketUpdateAdminRequest = (formData: FormData): Promise<AxiosResponse<boolean>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/admin/v2`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}
export const updateIssuedWellnessTicketDetail = (body: IIssuedWellnessTicketUpdateDetailAdminRequestV2): Promise<AxiosResponse<boolean>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/update/detail/admin/v2`, body);
}
export const getMyIssuedWellnessTicketListByMemberId = (memberId: number, wellnessLectureId: number) => {
    return api.get<IIssuedWellnessTicketAdminMyByMemberIdResponse[]>(`/issued-wellness-ticket/my/admin/v2/${memberId}/${wellnessLectureId}`);
}
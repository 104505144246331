import { AxiosResponse } from 'axios';
import api from './Api'

export const requestRegisterCountAndPaidCountAndPriceByCreatedDateBetween = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/member/daily?startDate=${startDate}&endDate=${endDate}`)
}

export const requestRegisterCountAndPaidCountAndPriceToHourByCreatedDateBetween = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/member/hour?startDate=${startDate}&endDate=${endDate}`)
}

export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByCenter = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/center?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupBySignDay = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/daily?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByWeek = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/weekly?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByMonth = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/monthly?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByTicket = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/ticket?startDate=${startDate}&endDate=${endDate}`)
}

export const getReservationCountByWellnessLectureStartDateTimeBetweenGroupByTeacherAndReservationStatus = (startZDT: string, endZDT: string): Promise<AxiosResponse<ReportTeacherReservationAdminResponseV1[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/report/teacher/reservation/admin/v1?startZDT=${encodeURIComponent(startZDT)}&endZDT=${encodeURIComponent(endZDT)}`)
}
export const getReservationCountAndDailyByWellnessLectureStartDateTimeBetweenGroupByTeacherAndDaily = (startZDT: string, endZDT: string): Promise<AxiosResponse<ReportTeacherReservationDailyAdminResponseV1[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/report/teacher/reservation/daily/admin/v1?startZDT=${encodeURIComponent(startZDT)}&endZDT=${encodeURIComponent(endZDT)}`)
}

export const getReservationCountAndWeekdayByWellnessLectureStartDateTimeBetweenGroupByTeacherAndWeekday = (startZDT: string, endZDT: string): Promise<AxiosResponse<ReportTeacherReservationWeeklyAdminResponseV1[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/report/teacher/reservation/weekly/admin/v1?startZDT=${encodeURIComponent(startZDT)}&endZDT=${encodeURIComponent(endZDT)}`)
}

export const getReservationCountByWellnessLectureStartDateTimeBetweenGroupByTimeAndTeacher = (startZDT: string, endZDT: string): Promise<AxiosResponse<IReportTeacherReservationTimeAdminResponseV1[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/report/teacher/reservation/time/admin/v1?startZDT=${encodeURIComponent(startZDT)}&endZDT=${encodeURIComponent(endZDT)}`)
}
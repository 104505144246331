import { Table } from "antd"
import { ColumnsType } from "antd/es/table";

interface IProps {
    data?: any[]
    isLoading?: boolean
}

interface DataType {
    key: string;
    teacherName: string;
    monday: number
    tuesday: number
    wednesday: number
    thursday: number
    friday: number
    saturday: number
    sunday: number
    total: number
}

const TeacherReportReservationDailyTable = ({ data, isLoading = false }: IProps) => {
    const columns: ColumnsType<DataType> = [
        {
            title: '이름', dataIndex: 'teacherName', filters: data?.map(q => ({ value: q.teacherName, text: q.teacherName })), filterSearch: true, onFilter: (value, record) => record.teacherName.includes(value.toString()),
            sorter: (a: any, b: any) => a.teacherName.localeCompare(b.teacherName),
        },
        { title: '월요일', dataIndex: 'monday', render: q => q?.toLocaleString(), sorter: (a, b) => a.monday - b.monday },
        { title: '화요일', dataIndex: 'tuesday', render: q => q?.toLocaleString(), sorter: (a, b) => a.tuesday - b.tuesday },
        { title: '수요일', dataIndex: 'wednesday', render: q => q?.toLocaleString(), sorter: (a, b) => a.wednesday - b.wednesday },
        { title: '목요일', dataIndex: 'thursday', render: q => q?.toLocaleString(), sorter: (a, b) => a.thursday - b.thursday },
        { title: '금요일', dataIndex: 'friday', render: q => q?.toLocaleString(), sorter: (a, b) => a.friday - b.friday },
        { title: '토요일', dataIndex: 'saturday', render: q => q?.toLocaleString(), sorter: (a, b) => a.saturday - b.saturday },
        { title: '일요일', dataIndex: 'sunday', render: q => q?.toLocaleString(), sorter: (a, b) => a.sunday - b.sunday },
        { title: '총합', dataIndex: 'total', render: q => q?.toLocaleString(), sorter: (a, b) => a.total - b.total },
    ];

    return <>
        <Table loading={isLoading} size="small" columns={columns} dataSource={data} bordered pagination={{ pageSize: 20 }} />
    </>
}
export default TeacherReportReservationDailyTable
import { useSelector } from "react-redux";
import SuperAdminPassword from "../../../../components/SuperAdminPassword"
import DateRangePicker from "../../ui/dateRangePicker";
import { RootState } from "../../../../modules";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getReservationCountAndWeekdayByWellnessLectureStartDateTimeBetweenGroupByTeacherAndWeekday } from "../../../../service/report";
import TeacherReportReservationWeeklyChart from "./ui/chart";
import { reportTeacherReservationWeeklyGroupDataByNameAndStatus, reportTeacherReservationWeeklyGroupDataByNameAndWeekly } from "./model";
import { TeacherReportReservationBasicTable, TeacherReportReservationWeeklyTable } from "./ui/table";

interface DataType extends ReportTeacherReservationWeeklyAdminResponseV1 {
    key: number;
    isMain: boolean;
}

interface IWeeklyDataType {
    teacherId: string | number;
    teacherName: string;
    key: string | number;
    [key: `weekStart${number}`]: string;
    [key: `weekEnd${number}`]: string;
    [key: `totalStatusCount${number}`]: number;
    [key: `statusCount${number}`]: number;
}

const ReportTeacherReservationWeekly = () => {
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);
    const [data, setData] = useState<ReportTeacherReservationWeeklyAdminResponseV1[]>([]);
    const [basicData, setBasicData] = useState<DataType[]>([]);
    const [weeklyData, setWeeklyData] = useState<IWeeklyDataType[]>([]);
    const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
    const startZDT = dayjs().set('h', 0).set('minute', 0).set('s', 0).subtract(7, 'week')
    const requestReportMember = (startZDT: string, endZDT: string) => {
        if (!isSuperAdmin) return;
        setIsGetLoading(true);
        getReservationCountAndWeekdayByWellnessLectureStartDateTimeBetweenGroupByTeacherAndWeekday(startZDT, endZDT)
            .then(res => {
                setData(res.data);
                setBasicData(reportTeacherReservationWeeklyGroupDataByNameAndStatus(res.data, dayjs(startZDT)).map((q, idx) => ({ ...q, key: idx })));
                setWeeklyData(reportTeacherReservationWeeklyGroupDataByNameAndWeekly(res.data, dayjs(startZDT)).map((q, idx) => ({ ...q, key: idx })))
            })
            .catch(err => { console.error(err); })
            .finally(() => setIsGetLoading(false));
    }

    useEffect(() => {
        requestReportMember(startZDT.toISOString(), dayjs().toISOString())
    }, [isSuperAdmin])

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }
    return <>
        <DateRangePicker
            startDate={startZDT}
            onClick={(startDate: string, endDate: string) => requestReportMember(dayjs(startDate + 'T00:00:00').toISOString(), dayjs(endDate).toISOString())} />
        <br /><br />
        <TeacherReportReservationWeeklyChart value={data.map((q, idx) => ({ ...q, key: idx }))} />
        <br /><br />
        <TeacherReportReservationWeeklyTable data={weeklyData} isLoading={isGetLoading} />
        <br /><br />
        <TeacherReportReservationBasicTable data={basicData} isLoading={isGetLoading} />
    </>

}

export default ReportTeacherReservationWeekly;
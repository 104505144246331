import api from './Api'

export const getMemberShipByMemberShipId = (memberShipId: string) => {
    return api.get<IMemberShipAdminResponseV2>(`member-ship/admin/v2/${memberShipId}`)
}
export const getAllMemberShip = () => {
    return api.get<IMemberShipAdminResponseV2[]>(`member-ship/admin/v2`)
}
export const createMemberShipByMemberShipPostAdminRequestV2 = (memberShipPostAdminRequestV2: IMemberShipPostAdminRequestV2) => {
    return api.post<boolean>(`member-ship/admin/v2`, memberShipPostAdminRequestV2);
}
export const updateMemberShipByMemberShipPutAdminRequestV2 = (memberShipPutAdminRequestV2: IMemberShipPutAdminRequestV2) => {
    return api.put<boolean>(`member-ship/admin/v2`, memberShipPutAdminRequestV2);
}

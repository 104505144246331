import { AxiosResponse } from 'axios';
import api from './Api'

export const getMemberByToken = (): Promise<AxiosResponse<IMemberByToken>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/token`);
}
export const getMemberByMobileEndingWith = (mobile: string): Promise<AxiosResponse<Array<ICheckInMember>>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/mobile/${mobile}`);
}
export const updateMemo = (memoPutRequest: IMemoPutRequest) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/member/memo`, memoPutRequest)
}
export const createMember = (member: ICreateMember) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/member`, member);
}
export const getCompanyMemberList = (companyId: number, searchValue: string, remainingCnt: string, remainingDate: string): Promise<AxiosResponse<Array<IMember>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/company?companyId=${companyId}&search=${searchValue}&remainingCnt=${remainingCnt}&remainingDate=${remainingDate}`)
}
export const updateMemberRoleList = (authRoleNameListRequest: any) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/auth/admin/role`, authRoleNameListRequest)
}
export const getMemberDetailDataByMemberId = (memberId: string): Promise<AxiosResponse<IMemberBasicAdminResponseV2>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/admin/v2/detail/${memberId}`);
}
export const getAllMemberMobileAndName = () => {
    return api.get<IMemberMobileAndName[]>(`/member/mobile-name/admin/v2`);
}
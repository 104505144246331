import { ConfigProvider } from 'antd';
import './App.css'
import Routes from './routes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={{ cssVar: true }}>
        <Routes />
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
import { Column } from '@ant-design/plots';
interface IProps {
    value?: any[]
}
const TeacherReportTimeChart = ({ value }: IProps) => {
    const config = {
        data: value,
        xField: 'targetTime',
        yField: 'reservationCount',
        colorField: 'teacherName',
        group: true,
        label: {
            position: 'outside',
            formatter: (text: string, datum: any, index: number, data: any) => `${datum.teacherName}\n${datum.reservationCount}\n\n`
        },
        scrollbar: {
            x: {
                ratio: 1 / 12,
            },
        },
    };
    return <Column {...config} />;
}

export default TeacherReportTimeChart
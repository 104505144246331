import { Column, CommonConfig } from '@ant-design/plots';
import { ColumnOptions } from '@ant-design/plots/es/core';
import dayjs from 'dayjs';
interface IProps {
    value?: any[]
}
const TeacherReportReservationWeeklyChart = ({ value }: IProps) => {
    const config: CommonConfig<ColumnOptions> = {
        data: value?.map(q => ({ ...q, weekStart: dayjs(q.weekStart).format('YYYY-MM-DD') + ' ~ ' + dayjs(q.weekEnd).format('YYYY-MM-DD') })),
        xField: 'weekStart',
        yField: 'totalStatusCount',
        colorField: 'teacherName',
        height: 800,
        group: true,
        label: {
            position: 'outside',
            formatter: (text: string, datum: any) => `${datum.teacherName}\n${text}\n\n`,
        },
        scrollbar: {
            x: {
                ratio: 1 / 8,
            },
        },
    };
    return <Column {...config} />;
}

export default TeacherReportReservationWeeklyChart
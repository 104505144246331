import { createAction } from 'typesafe-actions';

export const UPDATE_SELECTEDCENTER = 'selectedCenter/UPDATE_SELECTEDCENTER';
export const UPDATE_SELECTPERIOD = 'selectedCenter/UPDATE_SELECTPERIOD';
export const UPDATE_PICKDATE = 'selectedCenter/UPDATE_PICKDATE';
export const UPDATE_RANGEDATE = 'selectedCenter/UPDATE_RANGEDATE';

export const getSelectedCenterAsync = createAction(UPDATE_SELECTEDCENTER, ({ ...parameters }) => ({ ...parameters }))()
export const updateSelectPeriod = createAction(UPDATE_SELECTPERIOD, ({ ...parameters }) => ({ ...parameters }))()
export const updatePickDate = createAction(UPDATE_PICKDATE, ({ ...parameters }) => ({ ...parameters }))()
export const updateRangeDate = createAction(UPDATE_RANGEDATE, ({ ...parameters }) => ({ ...parameters }))()



import { AxiosResponse } from 'axios'
import api from './Api'

export const getAllScheduleInfo = (): Promise<AxiosResponse<IScheduleInfoBasicAdminResponse[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/schedule-info/admin/v2`)
}

export const getScheduleInfoByScheduleInfoId = (scheduleInfoId: number): Promise<AxiosResponse<IScheduleInfoBasicAdminResponse>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/schedule-info/admin/v2/${scheduleInfoId}`)
}

export const createScheduleInfoByScheduleInfoCreateAdminRequest = (scheduleInfoCreateAdminRequest: IScheduleInfoCreateAdminRequest): Promise<AxiosResponse<boolean>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/schedule-info/admin/v2`, scheduleInfoCreateAdminRequest, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const updateScheduleInfoByScheduleInfoUpdateAdminRequest = (scheduleInfoUpdateAdminRequest: IScheduleInfoUpdateAdminRequest): Promise<AxiosResponse<boolean>> => {
    return api.patch(`${process.env.REACT_APP_API_DOMAIN}/schedule-info/admin/v2`, scheduleInfoUpdateAdminRequest, { headers: { 'Content-Type': 'multipart/form-data' } })
}
export const converteYogaCenter = (conselingBranch: string | null | undefined) => {
  if (!conselingBranch) { return "미지정(개발전 결제)"; }
  switch (conselingBranch) {
    case "GANGNAM":
      return "강남";
    case "DOSAN":
      return "도산";
    case "SEOLLEUNG":
      return "선릉";
    default:
      return "선릉";
  }
}
export const convertYogacenterIdListToText = (numbers: number[]): string[] => {
  return numbers.map((num) => {
    switch (num) {
      case 1:
        return '강남';
      case 2:
        return '도산';
      case 3:
        return '선릉';
      default:
        return String(num);
    }
  });
};

export const convertYogacenterIdToText = (centerId: number): string => {
  switch (centerId) {
    case 1:
      return '강남';
    case 2:
      return '도산';
    case 3:
      return '선릉';
    default:
      return String(centerId);
  };
};

export const convertYogaCenterNameByIdList = (value: { yogaCenterIdList: number[] }) => {
  return (value?.yogaCenterIdList?.length === 3
    ? 'ALL PASS '
    : ((value?.yogaCenterIdList?.length === 1) && (value?.yogaCenterIdList[0] === 1)
      ? '강남 '
      : ((value?.yogaCenterIdList?.length === 1) && (value?.yogaCenterIdList[0] === 2)
        ? '도산 '
        : ((value?.yogaCenterIdList?.length === 1) && (value?.yogaCenterIdList[0] === 3)
          ? '선릉 '
          : (value?.yogaCenterIdList?.length === 2)
            ? convertYogacenterIdListToText(value?.yogaCenterIdList)
            : 'undefind'))))
}

export const ticketToValueAndLabel = (i: ITicket, selectedCompanyData: ICompanyData) => {
  const yogaCenterLength = i.yogaCenterIdList?.toString().split(',').length;
  const label = yogaCenterLength === 1
    ? `${selectedCompanyData.yogaCenterList.find((yogaCenter) => yogaCenter.id.toString() === i.yogaCenterIdList?.toString())?.name} [금액: ${i.discountedPrice?.toLocaleString()} 원]-할인률: ${i.discountRate}`
    : yogaCenterLength === 2
      ? `DOUBLEPASS [금액: ${i.discountedPrice?.toLocaleString()}원]-할인률: ${i.discountRate}`
      : yogaCenterLength === 3
        ? `ALLPASS [금액: ${i.discountedPrice?.toLocaleString()} 원]-할인률: ${i.discountRate}`
        : `undefined [금액: ${i.discountedPrice?.toLocaleString()} 원]-할인률: ${i.discountRate}`
  return { value: i.id, label: label };
}


export const invertHex = (hex: string) => {
  const hexWithoutHash = hex.replace('#', '');
  const fullHex = hexWithoutHash.length === 3
    ? hexWithoutHash.split('').map(char => char + char).join('')
    : hexWithoutHash;
  const invertedHex = (parseInt(fullHex, 16) ^ 0xFFFFFF).toString(16).padStart(6, '0');
  return `#${invertedHex}`;
};

const daysMap: { [key: number]: string } = {
  1: "월",
  2: "화",
  3: "수",
  4: "목",
  5: "금",
  6: "토",
  7: "일",
};

export const numberToday = (num: number): string => {
  return daysMap[num] || num?.toString(); // 유효하지 않은 숫자는 에러 처리
};
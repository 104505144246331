import { useState, useEffect } from 'react'
import { requestForToken, onMessageListener } from './firebase';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { Button, message, notification } from 'antd';
import { createAdminFcmTokenByToken } from '../../service/adminFcmToken';

const Notification = () => {
    let audio = new Audio('/bell-sound.mp3')
    const [messageFromFcm, setMessageFromFcm] = useState({ title: '', body: '' });
    const [api, contextHolder] = notification.useNotification();
    const [selectYogaCenterId, setSelectYogaCenterId] = useState<number>(0);
    const [fcmToken, setFcmToken] = useState<string>('');

    const playSound = () => {
        audio.play();
    }

    const openNotification = (placement: NotificationPlacement) => {
        api.info({
            message: `체크인 알림`,
            description: <>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{messageFromFcm.title}</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {messageFromFcm.body}
                </div>
            </>,
            placement,
        });
    };

    useEffect(() => {
        audio.load();
        requestForToken().then((currentToken) => {
            if (currentToken) {
                setFcmToken(currentToken);
            }
        })
    }, [])

    const yogaCenterIdMap: { [key: number]: string } = {
        1: '강남점',
        2: '도산점',
        3: '선릉점',
    }

    const requestAdminFcmToken = async (yogaCenterId: number) => {
        setSelectYogaCenterId(yogaCenterId)
        if (!yogaCenterId || yogaCenterId === 0) return;
        try {
            await createAdminFcmTokenByToken({ id: 0, yogaCenterId, token: fcmToken })
            message.success(yogaCenterIdMap[yogaCenterId] + ' 알림 설정 완료')
        } catch (err) {
            console.error('error', err)
        }
    }

    useEffect(() => {
        if (messageFromFcm?.title) {
            openNotification('top');
            playSound();

        }
    }, [messageFromFcm])



    onMessageListener()
        .then((payload: any) => {
            setMessageFromFcm({ title: payload?.notification?.title, body: payload?.notification?.body });
        })
        .catch((err) => console.error('failed: ', err));

    return (
        <>
            {contextHolder}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                <div>
                    <Button type={selectYogaCenterId === 3 ? 'primary' : 'default'} onClick={() => requestAdminFcmToken(3)} >
                        <div>선릉점</div>
                    </Button>
                </div>
                <div>
                    <Button type={selectYogaCenterId === 2 ? 'primary' : 'default'} onClick={() => requestAdminFcmToken(2)} >
                        <div>도산점</div>
                    </Button>
                </div>
                <div>
                    <Button type={selectYogaCenterId === 1 ? 'primary' : 'default'} onClick={() => requestAdminFcmToken(1)} >
                        <div>강남점</div>
                    </Button>
                </div>
                <div>
                    <Button type={selectYogaCenterId === 0 ? 'primary' : 'default'} onClick={() => requestAdminFcmToken(0)} >
                        <div style={{ textAlign: 'center' }}>알림 없음</div>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Notification
import { Column, CommonConfig } from '@ant-design/plots';
import { ColumnOptions } from '@ant-design/plots/es/core';
interface IProps {
    value?: any[]
}
const TeacherReportReservationDailyChart = ({ value }: IProps) => {
    const config: CommonConfig<ColumnOptions> = {
        data: value,
        xField: 'day',
        yField: 'reservationCount',
        colorField: 'teacherName',
        height: 600,
        group: true,
        label: {
            position: 'outside',
            formatter: (text: string, datum: any) => `${datum.teacherName}\n${text}\n\n`,
        },
        scrollbar: {
            x: {
                ratio: 1 / 8,
            },
        },
    };
    return <Column {...config} />;
}

export default TeacherReportReservationDailyChart
import { useQuery } from "@tanstack/react-query";
import { Button, Table } from "antd";
import { getAllMemberShip } from "../../service/memberShip";
import { columns } from "./model";


const MemberShip = () => {
    const { data, isPending } = useQuery({
        queryKey: ['getAllMemberShip'],
        queryFn: getAllMemberShip,
        select: res => res.data,
        staleTime: 1000 * 60 * 5
    })

    return <>
        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
            <Button type="primary" onClick={() => window.location.href = '/member-ship/create'}>생성</Button>
        </div>
        <Table
            columns={columns}
            dataSource={data}
            loading={isPending} />
    </>
}


export default MemberShip;
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllScheduleInfo } from "../../../service/scheduleInfo";
import { useQuery } from "@tanstack/react-query";

const SettingScheduleInfo = () => {
    const navigate = useNavigate();
    const { data, isPending, isError, error } = useQuery({
        queryKey: ['getAllScheduleInfo'],
        queryFn: () => getAllScheduleInfo(),
        staleTime: 1000 * 60 * 5,
        select: q => q.data
    })
    const columns: ColumnsType<any> = [
        {
            title: '요가원',
            dataIndex: 'yogaCenterName',
            render: (q, w) => <Link to={`${w.id}`}>{q ?? '-'}</Link>,
        },
        {
            title: '이미지 URL',
            dataIndex: 'imgUrl',
        },
        {
            title: '수정 날짜',
            dataIndex: 'lastUpdatedDate',
            render: q => dayjs(q).format('YYYY-MM-DD HH:mm'),
        },
    ];
    useEffect(() => {
        if (isError) {
            console.error('Error: ', error);
        }
    }, [isError, error]);

    return <>
        <div style={{ textAlign: 'right', marginBottom: '15px' }}>
            <Button type="primary" onClick={() => navigate('create')}>생성</Button>
        </div>
        <Table columns={columns} dataSource={data?.map((q, idx) => ({ ...q, key: idx }))} loading={isPending} pagination={false} />
    </>
}

export default SettingScheduleInfo;
import { Table } from "antd"
import { TableProps } from "antd/lib";

interface IProps {
    data?: any[]
    isLoading?: boolean
}

interface DataType {
    key: string;
    teacherId: number
    teacherName: string
    targetTime: string
    reservationCount: number
}

const TeacherReportTimeTable = ({ data, isLoading = false }: IProps) => {
    if (!data || data.length < 1) return <></>;
    const totalData = data[data.length - 1];
    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    const timeKeys = Object.keys(totalData).filter(q => timeFormatRegex.test(q))
    const columns: TableProps<any>['columns'] =
        [
            {
                title: '이름', dataIndex: 'teacherName', filterSearch: true, onFilter: (value, record) => record.teacherName.includes(value.toString()),
                filters: Array.from(new Map(data?.map(q => [q.teacherName, q])).values())?.map(q => ({ value: q.teacherName, text: q.teacherName })),
                sorter: (a: any, b: any) => a.teacherName.localeCompare(b.teacherName),
            },
            ...timeKeys.map((q: any) => ({
                title: q,
                dataIndex: q,
                render: (q: any) => q ? q : 0,
                sorter: (a: any, b: any) => (a[q] ?? 0) - (b[q] ?? 0),
            }))
        ];
    return <>
        <Table title={() => <>주별 예약 (출석 + 결석)</>} loading={isLoading} size="small" columns={columns} dataSource={data} bordered pagination={{ pageSize: 20 }} scroll={{ x: 1500 }} />
    </>
}
export default TeacherReportTimeTable
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, InputNumber, message } from "antd";
import { useNavigate } from "react-router-dom";
import { createMemberShipByMemberShipPostAdminRequestV2 } from "../../../service/memberShip";
import { AxiosError } from "axios";
import { memberShipKeyToKr } from "../model";

const MemberShipCreate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const createMutation = useMutation({
        mutationFn: (request: IMemberShipPostAdminRequestV2) => createMemberShipByMemberShipPostAdminRequestV2(request),
        onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['getAllMemberShip'] }); navigate('/member-ship') },
        onError: (err: AxiosError<string>) => message.error('이미 등록된 "' + memberShipKeyToKr(err?.response?.data) + '"입니다.')
    });

    const onFinish = (value: IMemberShipPostAdminRequestV2) => {
        createMutation.mutate(value)
    }

    return <>
        <Form
            onFinish={onFinish}
            labelAlign='left'
            colon={false}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
        >
            <Form.Item label='이름' name='name' rules={[{ required: true, message: '이름은 필수 입력값입니다.' }]} >
                <Input style={{ width: '300px' }} />
            </Form.Item>
            <Form.Item label='설명' name='description'>
                <Input.TextArea />
            </Form.Item>
            <Form.Item label='우선순위' name='priority' rules={[{ required: true, message: '우선순위는 필수 입력값입니다.' }]}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item noStyle name='benefitId' />
            <Form.Item label>
                <Button type="primary" loading={createMutation.isPending} htmlType='submit'>생성</Button>
                <Button style={{ marginLeft: '15px' }} onClick={() => navigate('/member-ship')} >취소</Button>
            </Form.Item>
        </Form>
    </>
}

export default MemberShipCreate;
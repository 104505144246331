import { Column, CommonConfig } from '@ant-design/plots';
import { ColumnOptions } from '@ant-design/plots/es/core';
interface IProps {
    value?: any[]
}
const TeacherReportReservaionChart = ({ value }: IProps) => {
    const config: CommonConfig<ColumnOptions> = {
        data: value,
        xField: 'teacherName',
        yField: 'count',
        colorField: 'status',
        group: true,
        label: {
            position: 'outside',
            formatter: (text: string, datum: any, index: number, data: any) => `${datum.status}\n${text}\n\n`,
        },
        style: {
            inset: 5,
        },
        scrollbar: {
            x: {
                ratio: 0.2,
            },
        },
    };
    return <Column {...config} />;
}

export default TeacherReportReservaionChart
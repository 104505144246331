import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, InputNumber, message } from "antd";
import { getMemberShipByMemberShipId, updateMemberShipByMemberShipPutAdminRequestV2 } from "../../../service/memberShip";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { memberShipKeyToKr } from "../model";

const MemberShipUpdate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: initData } = useQuery({
        queryKey: ['getMemberShipByMemberShipId', id],
        queryFn: () => getMemberShipByMemberShipId(String(id)),
        enabled: !!id,
        select: res => res.data
    })
    const updateMutation = useMutation({
        mutationFn: (request: IMemberShipPutAdminRequestV2) => updateMemberShipByMemberShipPutAdminRequestV2(request),
        onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['getAllMemberShip'] }); queryClient.invalidateQueries({ queryKey: ['getMemberShipByMemberShipId', id] }); navigate('/member-ship') },
        onError: (err: AxiosError<string>) => message.error('이미 등록된 "' + memberShipKeyToKr(err?.response?.data) + '"입니다.')
    });

    const onFinish = (value: IMemberShipPutAdminRequestV2) => {
        if (!initData) return message.error('새로고침 후 다시 시도해주세요.');
        const isEqual = Object.keys(value).every((key) => value[key as keyof IMemberShipPutAdminRequestV2] === initData[key as keyof IMemberShipPutAdminRequestV2]);

        if (isEqual) {
            message.warning('변경된 내용이 없습니다.')
            return;
        }
        updateMutation.mutate(value)
    }

    return <>
        {initData &&
            <Form
                onFinish={onFinish}
                labelAlign='left'
                colon={false}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                initialValues={initData}
            >
                <Form.Item noStyle name='id' />
                <Form.Item label='이름' name='name' rules={[{ required: true, message: '이름은 필수 입력값입니다.' }]} >
                    <Input style={{ width: '300px' }} />
                </Form.Item>
                <Form.Item label='설명' name='description'>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label='우선순위' name='priority' rules={[{ required: true, message: '우선순위는 필수 입력값입니다.' }]}>
                    <InputNumber min={0} />
                </Form.Item>

                <Form.Item noStyle name='benefitId' />
                <Form.Item label>
                    <Button type="primary" loading={updateMutation.isPending} htmlType='submit'>수정</Button>
                    <Button style={{ marginLeft: '15px' }} onClick={() => navigate('/member-ship')} >취소</Button>
                </Form.Item>
            </Form>}
    </>
}


export default MemberShipUpdate;
import { AxiosResponse } from 'axios';
import api from './Api'

export const getYogaCenterById = (id: number): Promise<AxiosResponse<IYogaCenter, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/yoga-center/${id}`)
}
export const createYogaCenter = (yogaCenter: ICreateYogaCenter) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/yoga-center`, yogaCenter);
}
export const deleteYogaCenter = (idList: Array<number>, companyId: number): Promise<string> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/yoga-center`, { data: { idList: idList, companyId: companyId } })
};
export const getYogaCenterForNameById = (id: number): Promise<AxiosResponse<string, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/yoga-center/name/${id}`)
}

export const updateYogaCenter = (yogaCenter: IYogaCenter) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/yoga-center`, yogaCenter)
}
export const updateYogaCenterBasicInfo = (yogaCenter: IUpdateYogaCenterBasicInfo) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/yoga-center`, yogaCenter)
}
export const updateYogaCenterDetailInfo = (yogaCenter: IUpdateYogaCenterDetailInfo) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/yoga-center`, yogaCenter)
}
export const updateYogaCenterSchedule = (yogaCenter: IUpdateYogaCenterSchedule) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/yoga-center`, yogaCenter)
}

export const getYogaCenterListByCompanyId = (companyId: number): Promise<AxiosResponse<Array<IYogaCenter>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/yoga-center/company/${companyId}`)
}

export const getAllYogaCenterIdAndNameByCompanyId = (companyId: number): Promise<AxiosResponse<IYogaCenterIdAndNama[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/yoga-center/id-name/company/admin/v2/${companyId}`)
}
import { ColumnsType } from "antd/es/table"
import { Link } from "react-router-dom"

const columns: ColumnsType<IMemberShipAdminResponseV2> = [
    {
        title: '이름',
        dataIndex: 'name',
        render: (text, record) => <Link to={`/member-ship/${record.id}`}> {text} </Link>
    },
    {
        title: '설명',
        dataIndex: 'description',
        width: '70%'
    },
    {
        title: '우선 순위',
        dataIndex: 'priority'
    }
]

const defaultValues: Record<keyof IMemberShipAdminResponseV2, any> = {
    id: '아이디',
    name: '이름',
    description: '설명',
    priority: '우선순위',
    benefitId: '혜택',
};

const memberShipKeyToKr = (field?: string): string | undefined => {
    if (!field) return field;
    if (field in defaultValues) {
        return defaultValues[field as keyof IMemberShipAdminResponseV2];
    }
    return field;
};

export { columns, memberShipKeyToKr }
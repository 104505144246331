import dayjs, { Dayjs } from "dayjs";

export const reportTeacherReservationWeeklyGroupDataByNameAndStatus = (data: ReportTeacherReservationWeeklyAdminResponseV1[], startZDT: Dayjs) => {
    if (data.length === 0) return [];
    const grouped: { [key: string]: any } = {};
    data.forEach(item => {
        if (!grouped[item.teacherId]) {
            grouped[item.teacherId] = {
                teacherId: item.teacherId,
                teacherName: item.teacherName,
                totalStatusCount: item.totalStatusCount,
                weekStart: startZDT.toISOString(),
                weekEnd: dayjs().toISOString(),
                key: `${item.teacherId}-${item.weekStart}-${item.weekEnd}`,
                statusCount: {
                    CLASS_BEFORE: 0,
                    CHECK_IN_RESERVATION_CANCELED: 0,
                    CHECK_IN: 0,
                    ABSENT: 0,
                },
                isMain: true,
                children: [
                    {
                        statusCount: item.statusCount,
                        totalStatusCount: item.totalStatusCount,
                        weekStart: item.weekStart,
                        weekEnd: item.weekEnd,
                        key: `${item.teacherId}-${item.weekStart}-${item.weekEnd}-children`,
                        isMain: false,
                    }
                ],
            };
        } else {
            grouped[item.teacherId].totalStatusCount += item.totalStatusCount;
            grouped[item.teacherId].statusCount.CLASS_BEFORE += item.statusCount?.CLASS_BEFORE;
            grouped[item.teacherId].statusCount.CHECK_IN_RESERVATION_CANCELED += item.statusCount?.CHECK_IN_RESERVATION_CANCELED
            grouped[item.teacherId].statusCount.CHECK_IN += item.statusCount?.CHECK_IN
            grouped[item.teacherId].statusCount.ABSENT += item.statusCount?.ABSENT
            grouped[item.teacherId].children.push({
                statusCount: item.statusCount,
                totalStatusCount: item.totalStatusCount,
                weekStart: item.weekStart,
                weekEnd: item.weekEnd,
                key: `${item.teacherId}-${item.weekStart}-${item.weekEnd}-children`,
                isMain: false,
            });
        }
    })

    return Object.values(grouped);
}

export const reportTeacherReservationWeeklyGroupDataByNameAndWeekly = (data: ReportTeacherReservationWeeklyAdminResponseV1[], startZDT: Dayjs) => {
    if (data.length === 0) return [];

    const grouped: { [key: string]: any } = {};
    data.forEach(item => {
        if (!grouped[item.teacherId]) {
            grouped[item.teacherId] = {
                teacherId: item.teacherId,
                teacherName: item.teacherName,
                ['weekStart' + dayjs(item.weekStart).diff(startZDT, 'week')]: dayjs(item.weekStart).format('YYYY-MM-DD'),
                ['weekEnd' + dayjs(item.weekStart).diff(startZDT, 'week')]: dayjs(item.weekEnd).format('YYYY-MM-DD'),
                ['totalStatusCount' + dayjs(item.weekStart).diff(startZDT, 'week')]: item.totalStatusCount,
                ['statusCount' + dayjs(item.weekStart).diff(startZDT, 'week')]: item.statusCount.CLASS_BEFORE + item.statusCount.CHECK_IN + item.statusCount.ABSENT,
                key: item.teacherId,
            };
        } else {
            grouped[item.teacherId]['weekStart' + dayjs(item.weekStart).diff(startZDT, 'week')] = dayjs(item.weekStart).format('YYYY-MM-DD');
            grouped[item.teacherId]['weekEnd' + dayjs(item.weekStart).diff(startZDT, 'week')] = dayjs(item.weekEnd).format('YYYY-MM-DD');
            grouped[item.teacherId]['totalStatusCount' + dayjs(item.weekStart).diff(startZDT, 'week')] = item.totalStatusCount;
            grouped[item.teacherId]['statusCount' + dayjs(item.weekStart).diff(startZDT, 'week')] = item.statusCount.CLASS_BEFORE + item.statusCount.CHECK_IN + item.statusCount.ABSENT;
        }
    })

    return Object.values(grouped);
}
import { Table } from "antd"
import { TableProps } from "antd/lib";
import dayjs from "dayjs";

interface IProps {
    data: DataType[] | IWeeklyDataType[]
    isLoading?: boolean
}

interface DataType extends ReportTeacherReservationWeeklyAdminResponseV1 {
    key: number;
    isMain: boolean;
}

export const TeacherReportReservationBasicTable = ({ data, isLoading = false }: IProps) => {
    const columns: TableProps<DataType>['columns'] = [
        {
            title: '이름', dataIndex: 'teacherName', filterSearch: true, onFilter: (value, record) => record.teacherName.includes(value.toString()),
            filters: Array.from(new Map(data?.map(q => [q.teacherName, q])).values())?.map(q => ({ value: q.teacherName, text: q.teacherName })),
            sorter: (a: any, b: any) => a.teacherName.localeCompare(b.teacherName),
        },
        { title: '날짜', onCell: q => ({ style: { color: q.isMain ? 'blue' : '' } }), render: (q, w) => dayjs(w.weekStart).format('YYYY-MM-DD') + " ~ " + dayjs(w.weekEnd).format('YYYY-MM-DD') },
        { title: '수업전', render: (_, value) => value?.statusCount.CLASS_BEFORE, sorter: (a, b) => a.statusCount?.CLASS_BEFORE - b.statusCount?.CLASS_BEFORE, },
        { title: '예약 취소', render: (_, value) => value?.statusCount.CHECK_IN_RESERVATION_CANCELED, sorter: (a, b) => a.statusCount?.CHECK_IN_RESERVATION_CANCELED - b.statusCount?.CHECK_IN_RESERVATION_CANCELED, },
        { title: '출석', render: (_, value) => value?.statusCount.CHECK_IN, sorter: (a, b) => a.statusCount?.CHECK_IN - b.statusCount?.CHECK_IN, },
        { title: '결석', render: (_, value) => value?.statusCount.ABSENT, sorter: (a, b) => a.statusCount?.ABSENT - b.statusCount?.ABSENT, },
        { title: '총합', dataIndex: 'totalStatusCount', sorter: (a, b) => a.totalStatusCount - b.totalStatusCount, },
    ];

    return <>
        <Table title={() => <>주별 예약 (상세)</>} loading={isLoading} size="small" columns={columns} dataSource={data as DataType[]} bordered pagination={{ pageSize: 20 }} />
    </>
}

interface IWeeklyDataType {
    teacherId: string | number;
    teacherName: string;
    key: string | number;
    [key: `weekStart${number}`]: string;
    [key: `weekEnd${number}`]: string;
    [key: `totalStatusCount${number}`]: number;
    [key: `statusCount${number}`]: number;
}
export const TeacherReportReservationWeeklyTable = ({ data, isLoading = false }: IProps) => {
    let columnsWeekly: TableProps<IWeeklyDataType>['columns'] = [];
    if (data.length > 0) {
        const filterdKey = Object.keys((data as IWeeklyDataType[])[0]).filter(q => /weekStart\d$/.test(q));
        columnsWeekly = [
            {
                title: '이름', dataIndex: 'teacherName', filterSearch: true, onFilter: (value, record) => record.teacherName.includes(value.toString()),
                filters: Array.from(new Map(data?.map(q => [q.teacherName, q])).values())?.map(q => ({ value: q.teacherName, text: q.teacherName })),
                sorter: (a: any, b: any) => a.teacherName.localeCompare(b.teacherName),
            },
            ...filterdKey.map(q => ({ title: (data as IWeeklyDataType[])[0][q as keyof IWeeklyDataType] + ' ~ ' + (data as IWeeklyDataType[])[0]['weekEnd' + q[q.length - 1] as keyof IWeeklyDataType], dataIndex: 'statusCount' + q[q.length - 1], sorter: (a: any, b: any) => a['statusCount' + q[q.length - 1]] - b['statusCount' + q[q.length - 1]], }))];
    }

    return <>
        <Table title={() => <>주별 예약 (출석 + 결석)</>} loading={isLoading} size="small" columns={columnsWeekly} dataSource={data as IWeeklyDataType[]} bordered pagination={{ pageSize: 20 }} scroll={{ x: 1500 }} />
    </>
}
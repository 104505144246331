import { createReducer } from 'typesafe-actions';
import { SelectedCenterState, SelectedCenterAction } from './types';
import { UPDATE_PICKDATE, UPDATE_RANGEDATE, UPDATE_SELECTEDCENTER, UPDATE_SELECTPERIOD } from './actions';
import dayjs from 'dayjs';


const initialState: SelectedCenterState = {
    companyId: 1,
    yogaCenterId: 1,
    selectedPeriod: 1,
    pickDate: dayjs(),
    rangeDate: [dayjs(), dayjs().add(1, 'month')]
};

const yogaCenter = createReducer<SelectedCenterState, SelectedCenterAction>(initialState, {
    [UPDATE_SELECTEDCENTER]: (state, action) => ({
        ...state,
        companyId: action.payload.companyId,
        yogaCenterId: action.payload.yogaCenterId,
    }),
    [UPDATE_SELECTPERIOD]: (state, action) => ({
        ...state,
        selectedPeriod: action.payload.selectedPeriod,
    }),
    [UPDATE_PICKDATE]: (state, action) => ({
        ...state,
        pickDate: action.payload.pickDate,
    }),
    [UPDATE_RANGEDATE]: (state, action) => ({
        ...state,
        rangeDate: action.payload.rangeDate,
    })
});

export default yogaCenter;
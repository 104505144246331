import { Table } from "antd"
import { TableProps } from "antd/lib";

interface IProps {
    data?: any[]
    isLoading?: boolean
}

interface DataType {
    key: string;
    teacherName: string;
    RESERVATION: number;
    CHECK_IN: number;
    ABSENT: number;
    ABSENTRatio: number;
    RESERVATIONRatio: number;
    CHECK_IN_RESERVATION_CANCELED: number;
}

const TeacherReportReservationTable = ({ data, isLoading = false }: IProps) => {
    const columns: TableProps<DataType>['columns'] = [
        {
            title: '이름', dataIndex: 'teacherName', filters: data?.map(q => ({ value: q.teacherName, text: q.teacherName })), filterSearch: true, onFilter: (value, record) => record.teacherName.includes(value.toString()),
            sorter: (a: any, b: any) => a.teacherName.localeCompare(b.teacherName),
        },
        { title: '예약', dataIndex: 'RESERVATION', render: q => q?.toLocaleString(), sorter: (a, b) => a.RESERVATION - b.RESERVATION },
        { title: '수업 전', dataIndex: 'CLASS_BEFORE', render: q => q?.toLocaleString() },
        { title: '출석', dataIndex: 'CHECK_IN', render: q => q?.toLocaleString(), sorter: (a, b) => a.CHECK_IN - b.CHECK_IN },
        {
            title: '결석',
            children: [
                { title: '결석', dataIndex: 'ABSENT', render: q => q?.toLocaleString() },
                { title: '결석률', dataIndex: 'ABSENTRatio', render: q => q + '%', sorter: (a, b) => a.ABSENTRatio - b.ABSENTRatio }
            ]
        },
        {
            title: '예약 취소',
            children: [
                { title: '예약 취소', dataIndex: 'CHECK_IN_RESERVATION_CANCELED', render: q => q?.toLocaleString() },
                { title: '예약 취소율', dataIndex: 'RESERVATIONRatio', render: q => q + '%', sorter: (a, b) => a.RESERVATIONRatio - b.RESERVATIONRatio },
            ]
        }
    ];

    return <>
        <Table loading={isLoading} size="small" columns={columns} dataSource={data} bordered />
    </>
}
export default TeacherReportReservationTable